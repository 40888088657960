import logo from './logo.svg';
import './App.css';
import { useState } from 'react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import LocalCafeRoundedIcon from '@mui/icons-material/LocalCafeRounded';
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';
import PetsIcon from '@mui/icons-material/Pets';
import instructions from './tifa-instructions.png';

function App() {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  const [value, setValue] = useState(null);
  return (
    <div className="site">
    <div className="manual" style={{
      margin: '0',
      padding: '0',
      display: 'grid',
      height: '100%',
    }}>
    <img src={instructions} alt="TIFÄ Instructions Manual" usemap="#tifamap" style={{
            margin: '0',
            padding: '0',
      maxWidth: '100%',
      maxHeight: '100vh',
      margin: 'auto',}}
      />
<map name="tifamap">
    <area alt="Kodning" title="Kodning" href="https://github.com/tifasnow" target="_blank" coords="19,4,538,369" shape="rect" />
    <area alt="Relacks" title="Relacks" href="https://t.tifaz.uk" target="_blank" coords="539,720,2,389" shape="rect" />
    <area alt="TVSpel" title="TVSpel" href="https://fae.fyi/@Tifa" target="_blank" coords="7,740,543,1076" shape="rect" />
    <area alt="Ixis" title="Ixis" href="https://www.furaffinity.net/view/50010621/" target="_blank" coords="1701,972,1874,1047" shape="rect" />
    <area alt="Speek" title="Speek" href="https://fae.fyi/@Tifa" target="_blank" coords="556,108,556,998,1026,998,1041,508,1111,520,1148,511,1175,499,1208,486,1191,448,1050,369,1052,238,1048,164,1045,120,1028,103,558,92" shape="poly" />
    <area alt="Snohund" title="Snohund" href="https://www.furaffinity.net/user/tifaz" target="_blank" coords="1077,777,1328,833" shape="rect" />
    <area alt="HjartMark" title="HjartMark" href="https://fae.fyi/@Tifa" target="_blank" coords="1072,190,1330,232" shape="rect" />
    <area alt="Floofi" title="Floofi" href="" target="_blank" coords="1660,356,1850,409" shape="rect" />
    <area alt="TIFÄ" title="Title" href="#" coords="1876,10,1537,163" shape="rect" />
</map>
    </div>
<BottomNavigation
  showLabels
  value={value}
  onChange={(event, newValue) => {
    setValue(newValue);
  }}
>
  <BottomNavigationAction label="About" icon={<InfoRoundedIcon />} onClick={()=>openInNewTab("https://thoughts.tifaz.uk/about/")} />
  <BottomNavigationAction label="Ko-Fi" icon={<LocalCafeRoundedIcon />} onClick={()=>openInNewTab("https://ko-fi.com/tifaz")} />
  <BottomNavigationAction label="Contact" icon={<AlternateEmailRoundedIcon />} onClick={()=>openInNewTab("https://thoughts.tifaz.uk/contact/")} />
  <BottomNavigationAction label="Tifa Z" icon={<PetsIcon />} onClick={()=>openInNewTab("#")} />
</BottomNavigation>
</div>
)};

export default App;
